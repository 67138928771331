<template>
  <section id="member_forget_password">
    <div
      class="sub"
      :style="{ backgroundImage: `url(${require('@/assets/img/member/bg_1.jpg')})` }"
    >
      <!-- <div class="sub_box">
        <div class="main_title txt-center txt-white">
          <h3 class="eng">new member</h3>
          <h5>
            還沒成為會員？
            <img class="deco_flower" src="@/assets/img/deco_flower_white.svg" alt="">
          </h5>
        </div>

        <router-link :to="{name: 'Signup'}" class="btn btn-white">
          立即加入
        </router-link>
      </div> -->
    </div>
    <div class="main">
      <div class="forget_box">
        <DecotTitle :title="$t('fogotPasswordTitle')" engTitle="FORGET PASSWORD" />

        <p class="small txt-dark_gray txt-center" :style="{ textAlign: txt }">
          {{ $t("fogotPasswordInfo") }}
        </p>

        <div class="form-wrap">
          <re-easy-form
            v-model="easyForm"
            :rules="forgetPasswordRule"
            :labelConfig="labelConfig"
            ref="reEasyForm"
          />

          <div class="btn-wrap">
            <re-button round="round" type="border" size="large" @click="submitEasyForm">{{
              $t("resetPasswordEmail")
            }}</re-button>
          </div>

          <div class="account-exist">
            <div class="account-exist">
              <p class="account-exist__text">{{ $t("hasAccount") }}</p>
              <router-link :to="{ name: 'Login' }" class="account-exist__to-login">
                <span class="account-exist__to-login__link">{{ $t("login") }}</span>
                <img class="account-exist__to-login__icon" src="@/assets/icons/arrow-right.svg" />
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div id="forget_password_lightbox" v-show="lightboxOpen">
      <div class="cover" @click="clickCloseLightbox()"></div>
      <div class="box">
        <div class="text txt-center">
          <img class="deco_check" src="@/assets/img/member/carry_out.svg" alt="" />
          <h5 class="txt-bold">
            {{ lightboxMessage.title }}
            <img class="deco_flower" src="@/assets/img/deco_flower.svg" alt="" />
          </h5>
          <p class="small txt-dark_gray" v-html="lightboxMessage.info"></p>
          <button type="button" class="btn btn-dark_solid p" @click="clickCloseLightbox()">
            {{ $t("complete") }}
          </button>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import '@/assets/scss/login.scss';

// import DatePick from 'vue-date-pick';
import 'vue-date-pick/dist/vueDatePick.css';
import { forgetPassword } from '@/lib/cakeHttp';
import { mapGetters, mapMutations } from 'vuex';
import ReButton from '@/components/form/ReButton.vue';
import ReEasyForm from '@/components/form/ReEasyForm.vue';
import DecotTitle from '@/components/global/DecoTitle.vue';
import { forgetPasswordRule } from '@/config/easyFormRule';
import { easyFormLabelConfig } from '@/config/easyFormLabel';
import { getApiLangCode } from '@/utils/getLangCode';

export default {
  name: 'ForgetPassword',
  components: {
    ReEasyForm,
    ReButton,
    DecotTitle
    // DatePick,
  },
  data() {
    return {
      dateTest: null,
      attributes: [
        {
          key: 'today',
          highlight: true,
          dates: new Date()
        }
      ],
      lightboxOpen: false,
      lightboxMessage: {
        title: '',
        info: ''
      },
      formData: {
        account: '',
        birthday: ''
      },
      txt: 'center',
      rememberMe: false,
      radioOptions: [
        {
          label: this.$t('rememberMe'),
          value: true
        }
      ],
      easyForm: [
        {
          label: this.$t('uidOrPassportNum'),
          prop: 'account',
          value: '',
          comp: 'input',
          placeholder: this.$t('uidOrPassportNum')
        },
        {
          label: this.$t('form.email'),
          prop: 'email',
          value: '',
          comp: 'input',
          placeholder: this.$t('form.email')
        }
      ],
      forgetPasswordRule,
      labelConfig: easyFormLabelConfig
    };
  },
  computed: {
    ...mapGetters(['getLanguage']),
    filterData() {
      return this.easyForm.reduce((obj, item) => {
        obj[item.prop] = item.value;

        return obj;
      }, {});
    }
  },
  methods: {
    ...mapMutations(['toggleShowMsg', 'setMsgInfo']),
    async submitEasyForm() {
      const checkForm = await this.$refs.reEasyForm.validateForm();
      if (checkForm.status) {
        this.sendForgetPassword();
      }
    },

    sendForgetPassword() {
      forgetPassword(
        {
          ...this.filterData,
          send_way: 1,
          lang_id: getApiLangCode(),
          type: 'web'
        },
        // 成功
        () => {
          // this.openLightbox(this.$t('mailSendingTitle'), this.$t('mailSendingContent'));

          this.toggleShowMsg(true);
          this.setMsgInfo({
            succeed: true,
            desc: this.$t('mailSendingTitle')
          });
        },
        // 失敗
        (err) => {
          this.toggleShowMsg(true);
          this.setMsgInfo({
            succeed: false,
            desc: err
          });
        }
      );
    },
    openLightbox(t, i) {
      this.lightboxOpen = true;
      this.lightboxMessage.title = t;
      this.lightboxMessage.info = i;
    },
    clickCloseLightbox() {
      this.lightboxOpen = false;
      this.$router.push({ path: '/login' });
    }
  },
  mounted() {
    if (this.getLanguage === 'en') {
      this.txt = 'left';
    }
    if (process.env.NODE_ENV === 'development') {
      this.formData = {
        account: '11111',
        birthday: '1988-01-01'
      };
    }
  }
  // clickCloseLightbox() {
  //   this.lightboxOpen = false;
  //   this.$router.push({ name: 'Login' });
  // },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/other/color.scss";
@import "@/assets/scss/other/mixin.scss";

.form-wrap {
  width: 100%;
  max-width: 350px;
  margin: 0 auto;
}

.btn-wrap {
  margin-top: 50px;
}

.account-exist {
  @include font-style($c-grey1, 14);
  // @include inline-flex(center);
  margin-top: 20px;
  text-align: center;

  &__text {
    display: inline-block;
    @include font-style($c-grey1, 14);
  }

  &__to-login {
    @include inline-flex(center, center);
    @include font-size(14);

    &__icon {
      width: 15px;
      color: $c-link;
    }

    &__link {
      color: $c-link;
    }
  }
}

@media screen and (max-width: 768px) {
  .form-wrap {
    width: 90%;
  }
}
</style>
